import getLocalStorageKey from 'api/localstorage/get';
import { LOCALSTORAGE } from 'constants/localStorage';
import { getInitialUserToken } from 'store/utils';
import {
	LOGGED_IN,
	UPDATE_USER_ACCESS_TOKEN,
	UPDATE_USER,
	LOADING_ACTIVE,
	LOADING_INACTIVE,
	UPDATE_CURRENT_ERROR,
	UPDATE_PAGE_TITLE,
	EXTEND_PAGE_TITLE,
	UPDATE_UE_GAME,
	UPDATE_UE_GAME_FILES,
	UPDATE_UE_GAME_UPLOADED_FILES,
	UPDATE_UE_GAME_UPLOADED_FILES_BLOB,
	UPDATE_UE_GAME_STATE,
	ADD_GENERATED_VOICE_FILE,
	ADD_GENERATED_VOICE_FILE_BLOB
} from 'constants/store';

const getInitialUser = () => {
	return {
		id: '',
		email: '',
		firstName: '',
    lastName: '',
		orgId: '',
    role: ''
	};
}

const InitialValue = {
	isLoggedIn: getLocalStorageKey(LOCALSTORAGE.IS_LOGGED_IN) === 'true',
	user: getInitialUser(),
	jwtToken: getInitialUserToken() || null,
	loading: false,
	pageTitle: '',
	ueGame: null,
	ueGameFiles: [],
	ueGameUploadedFiles: [],
	ueGameUploadedFilesBlob: [],
	ueGameUpdateState: ''
};

const appReducer = (
	state = InitialValue,
	action,
) => {
	switch (action.type) {
		case LOGGED_IN: {
			return {
				...state,
				isLoggedIn: action.payload.isLoggedIn,
			};
		}

		case UPDATE_USER_ACCESS_TOKEN: {
			return {
				...state,
				jwtToken: action.payload.jwtToken,
			};
		}

		case UPDATE_USER: {
			const user = state.user || {};
			const {
				email,
				firstName,
				lastName,
				id,
				role,
				org,
			} = action.payload;

			return {
				...state,
				user: {
					...user,
					email,
					firstName,
					lastName,
					id,
					role,
					org,
				},
			};
		}

		case LOADING_ACTIVE: {
			return {
				...state,
				loading: true
			}
		}

		case LOADING_INACTIVE: {
			return {
				...state,
				loading: false
			}
		}

		case UPDATE_CURRENT_ERROR: {
			const { message } = action.payload;
			return {
				...state,
				globalError: message
			}
		}

		case UPDATE_PAGE_TITLE: {
			const { title } = action.payload;
			return {
				...state,
				pageTitle: title,
			}
		}

		case EXTEND_PAGE_TITLE: {
			const { title } = action.payload;
			const updatedTitle = state.pageTitle.includes(':') ? state.pageTitle.split(':')[0].concat(': ').concat(`${title}`) : `${state.pageTitle}: ${title}`;
			const pageTitle = updatedTitle.length > 38 ? updatedTitle.slice(0, 35).concat('...') : updatedTitle;
			
			return {
				...state,
				pageTitle,
			}
		}

		case UPDATE_UE_GAME: {
			return {
				...state,
				ueGame: action.payload.ueGame
			}
		}

		case UPDATE_UE_GAME_FILES: {
			return {
				...state,
				ueGameFiles: action.payload.gameFiles.length ? [...state.ueGameFiles, ...action.payload.gameFiles] : []
			}
		}

		case UPDATE_UE_GAME_UPLOADED_FILES: {
			return {
				...state,
				ueGameUploadedFiles: action.payload.files.length ? [...state.ueGameUploadedFiles, ...action.payload.files] : []
			}
		}

		case UPDATE_UE_GAME_UPLOADED_FILES_BLOB: {
			return {
				...state,
				ueGameUploadedFilesBlob: action.payload.files.length ? [...state.ueGameUploadedFilesBlob, ...action.payload.files] : []
			}
		}

		case UPDATE_UE_GAME_STATE: {
			return {
				...state,
				ueGameUpdateState: action.payload.length ? [...state.ueGameUpdateState, ...action.payload] : []
			}
		}

		case ADD_GENERATED_VOICE_FILE: {
			const existsInUpload = state.ueGameUploadedFiles.find(uploadedFile => uploadedFile.filename === action.payload.file.filename);
			if (existsInUpload) {
				const uploadedFiles = state.ueGameUploadedFiles.filter(uploadedFile => uploadedFile.filename !== action.payload.file.filename)
				return {
					...state,
					ueGameUploadedFiles: [...uploadedFiles, action.payload.file]
				}
			}

			const existsInFiles = state.ueGameFiles.find(uploadedFile => uploadedFile.filename === action.payload.file.filename);
			if (existsInFiles) {
				const gameFiles = state.ueGameFiles.filter(gameFiles => gameFiles.filename !== action.payload.file.filename)
				return {
					...state,
					ueGameUploadedFiles: [...state.ueGameUploadedFiles, action.payload.file],
					ueGameFiles: gameFiles
				}
			}
			return {
				...state,
				ueGameUploadedFiles: [...state.ueGameUploadedFiles, action.payload.file]
			}
		}

		case ADD_GENERATED_VOICE_FILE_BLOB: {
			const exists = state.ueGameUploadedFilesBlob.find(uploadedFile => uploadedFile.name === action.payload.file.name);
			if (exists) {
				const uploadedFilesBlob = state.ueGameUploadedFilesBlob.filter(uploadedFile => uploadedFile.name !== action.payload.file.name)
				return {
					...state,
					ueGameUploadedFilesBlob: [...uploadedFilesBlob, action.payload.file]
				}
			}

			return {
				...state,
				ueGameUploadedFilesBlob: [...state.ueGameUploadedFilesBlob, action.payload.file]
			}
		}

		default:
			return state;
	}
};

export default appReducer;