import Checkbox from './checkbox'

const baseStyleControl = () => {
  const { control } = Checkbox.baseStyle

  return {
    ...control,
    borderRadius: "full",
    _checked: {
      ...(control)["_checked"],
      _before: {
        content: `""`,
        display: "inline-block",
        pos: "relative",
        width: '14px',
        height: '14px',
        borderRadius: "16px",
        bg: 'currentColor',
      },
    },
  }
}

const Radio = {
  baseStyle: {
    control: baseStyleControl(),
    container: {
      _hover: {
        "> span.chakra-radio__control[data-disabled]": {
          bg: '#434658',
          border: '1px solid #8E8F96;',
          boxShadow: '0px 3px 0px #434658',
          _hover: {
            boxShadow: '0px 3px 0px #434658',
            border: '1px solid #8E8F96',
          }
        },
        "> span.chakra-radio__control": {
          bg: '#434658',
          border: '1px solid #F8D060',
          boxShadow: '0px 3px 0px #BE8F18'
        },
      }
    }
  },
  sizes: {
    md: {
      control: { w: '24px', h: '24px' },
    }
  },
  // The default size
  defaultProps: {
    size: 'md',
  }
}

export default Radio