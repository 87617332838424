import getLocalStorageKey from 'api/localstorage/get';
import { LOCALSTORAGE } from 'constants/localStorage';

export const getInitialUserToken = () => {
	const accessJwt = getLocalStorageKey(LOCALSTORAGE.AUTH_TOKEN);

	if (accessJwt) {
		return {
			accessJwt,
		};
	}

	return null;
};
