const baseStyleList = {
  bg: "#202330",
  boxShadow: "dark-lg",
  color: "inherit",
  minW: "3xs",
  py: "0",
  zIndex: 1,
  borderRadius: "16px",
  border: "1px solid #F8D060",
}

const baseStyleItem = {
  "&:first-of-type": {
    borderRadius: '16px 16px 0px 0px'
  },
  "&:last-child": {
    borderRadius: '0px 0px 16px 16px'
  },
  py: "0.7rem",
  px: "1.5rem",
  transitionProperty: "background",
  transitionDuration: "slow",
  transitionTimingFunction: "ease-out",
  _focus: {
    bg: "#202330",
  },
  _active: {
    bg: "#202330",
  },
  _expanded: {
    bg: "#202330",
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
  },
  _hover: {
    bg: '#F8D060',
    color: '#202330',
  }
}

const baseStyleGroupTitle = {
  mx: 0,
  my: 0,
  fontWeight: "semibold",
  fontSize: "sm",
}

const baseStyleCommand = {
  opacity: 0.6,
}

const baseStyleDivider = {
  border: 0,
  borderBottom: "1px solid",
  borderColor: "inherit",
  my: 0,
  opacity: 0.6,
}

const baseStyleButton = {
  transitionProperty: "common",
  transitionDuration: "normal",
}

const baseStyle = {
  button: baseStyleButton,
  list: baseStyleList,
  item: baseStyleItem,
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider,
}

export default {
  baseStyle,
}