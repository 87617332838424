const baseStyleContainer = {
  fontWeight: "medium",
  lineHeight: 1.2,
  outline: 0,
  padding: '4px 12px',
  borderRadius: "32px",
  fontWeight: 400,
  fontSize: '14px',
  textTransform: 'uppercase',
  _focusVisible: {
    boxShadow: "outline",
  },
  cursor: 'default'
}

const baseStyleLabel = {
  lineHeight: 1.2,
  overflow: "visible",
}

const baseStyleCloseButton = {
  fontSize: "14px",
  w: "16px",
  h: "16px",
  transitionProperty: "common",
  transitionDuration: "normal",
  borderRadius: "full",
  bg: '#202330',
  color: '#FCFCFC',
  marginStart: "0.375rem",
  marginEnd: "-1",
  opacity: 1,
}

const baseStyle = {
  container: baseStyleContainer,
  label: baseStyleLabel,
  closeButton: baseStyleCloseButton,
}

const sizes = {
  sm: {
    container: {
      minH: "1.25rem",
      minW: "1.25rem",
      fontSize: "xs",
      px: 2,
    },
    closeButton: {
      marginEnd: "-2px",
      marginStart: "0.35rem",
    },
  },
  md: {
    container: {
      minH: "1.5rem",
      minW: "1.5rem",
      fontSize: "xs",
      px: 3,
    },
    closeButton: {
      marginEnd: "0",
      marginStart: "0.4rem",
    }
  },
  lg: {
    container: {
      minH: 8,
      minW: 8,
      fontSize: "sm",
      px: 3,
    },
  },
}

const Tag = {
  variants: {
    default: {
      container: {
        bg: '#F8D060',
        color: '#202330',
        _hover: { bg: '#FCFCFC' }
      }
    },
    secondary: {
      container: {
        bg: '#47D7E0',
        color: '#202330',
        _hover: { bg: '#FCFCFC' }
      }
    },
    danger: {
      container: {
        bg: '#D92048',
        color: '#FCFCFC',
        _hover: { bg: '#FCFCFC', color: '#202330' }
      }
    },
    neutral: {
      container: {
        bg: '#CCCCCC',
        color: '#202330',
        _hover: { bg: '#FCFCFC' }
      }
    },
    reversed: {
      container: {
        color: '#F8D060',
        bg: '#434658',
        fontWeight: 400,
        _hover: { border: '1px solid #FCFCFC', padding: '3px 11px' }
      }
    },
    'reversed-secondary': {
      container: {
        color: '#47D7E0',
        bg: '#434658',
        fontWeight: 400,
        _hover: { border: '1px solid #FCFCFC', padding: '3px 11px' }
      }
    },
    'reversed-neutral': {
      container: {
        color: '#FCFCFC',
        bg: '#434658',
        fontWeight: 400,
        _hover: { border: '1px solid #FCFCFC', padding: '3px 11px' }
      }
    },
    'reversed-danger': {
      container: {
        color: '#FFAABD',
        bg: '#434658',
        fontWeight: 400,
        _hover: { border: '1px solid #D92048', padding: '3px 11px', color: '#FCFCFC' }
      }
    },
    purple: {
      container: {
        bg: '#8038AC',
        color: '#FCFCFC',
        _hover: { bg: '#8038AC' }
      }
    },
    navy: {
      container: {
        bg: '#0184CD',
        color: '#FCFCFC',
        _hover: { bg: '#0184CD' }
      }
    },
    turquoise: {
      container: {
        bg: '#1E6B71',
        color: '#FCFCFC',
        _hover: { bg: '#1E6B71' }
      }
    },
  },
  baseStyle,
  sizes,
  defaultProps: {
    size: "md",
    variant: "default",
  },
}

export default Tag