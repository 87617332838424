import "./i18n";

import { lazy } from "react";
import { createRoot } from "react-dom/client";

// ** Redux Imports
import { Provider } from "react-redux";
import store from "store";

// ** Chakra UI Imports
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";

// ** Auth
import { Auth0Provider } from "@auth0/auth0-react";

import "./index.scss";
import reportWebVitals from "./reportWebVitals";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        redirectUri={window.location.origin}
      >
        <LazyApp />
      </Auth0Provider>
    </ChakraProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
