const Button = {
  // The styles all button have in common
  baseStyle: {
    borderRadius: '16px',
    fontWeight: 'normal',
    WebkitTapHighlightColor: 'transparent'
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      padding: '8px 12px',
      borderRadius: '12px'
    },
    md: {
      fontSize: 'md',
      padding: '16px 24px',
      borderRadius: '16px'
    }
  },
  variants: {
    default: {
      bg: '#F8D060',
      color: '#202330',
      boxShadow: '0px 3px 0px #BE8F18;',
      _hover: { 
        bg: '#F2B724',
        _disabled: {
          bg: '#F2B724'
        }
      },
      _active: { 
        boxShadow: '0px 1px 0px #F2B724;',
        transform: 'translateY(1px)' 
      },
      _disabled: {
        boxShadow: '0px 3px 0px #BE8F18;',
      }
    },
    secondary: {
      bg: '#272A37;',
      color: '#FCFCFC',
      border: '1px solid #FCFCFC',
      boxShadow: '0px 3px 0px #434658',
      _hover: { bg: '#434658', border: '1px solid #F8D060', boxShadow: '0px 3px 0px #BE8F18', color:' #F8D060' },
      _active: { bg: '#434658', border: '1px solid #F8D060', boxShadow: '0px 1px 0px #BE8F18', color:'#F8D060', transform: 'translateY(1px)' }
    },
    info: {
      background: '#47D7E0',
      color: '#202330',
      boxShadow: '0px 3px 0px #1E6B71',
      _hover: { bg: '#38A4AC' },
      _active: { bg: '#38A4AC', boxShadow: '0px 1px 0px #1E6B71', transform: 'translateY(1px)' }
    },
    danger: {
      background: '#D92048',
      color: '#FCFCFC',
      boxShadow: '0px 3px 0px #6F0A20',
      _hover: { bg: '#A21030' },
      _active: { bg: '#A21030', boxShadow: '0px 1px 0px #6F0A20', transform: 'translateY(1px)' }
    },
    neutral: {
      background: '#FCFCFC',
      color: '#202330',
      boxShadow: '0px 3px 0px #434658',
      _hover: { bg: '#CCCCCC' },
      _active: { bg: '#CCCCCC', boxShadow: '0px 1px 0px #434658', transform: 'translateY(1px)' }
    }
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'default'
  }
}

export default Button