const Input = {
  baseStyle: {
    field: {
      fontWeight: '400',
      padding: '16px 24px',
      letterSpacing: '0.8px'
    }
  },
  // Two sizes: sm and md
  sizes: {
    md: {
      field: {
        height: '2.8rem',
        fontSize: 'md',
        borderRadius: '8px'
      }
    },
    sm: {
      field: {
        borderRadius: '8px'
      }
    }
  },
  variants: {
    default: {
      field: {
        bg: '#272A37',
        color: '#FCFCFC',
        fontWeight: '400',
        border: '1px solid #8E8F96',
        boxShadow: '0px 3px 0px #434658',
        _hover: { bg: '#434658', border: '1px solid #F8D060', boxShadow: '0px 3px 0px #BE8F18', color: '#FCFCFC' },
        _focusVisible: { background: '#585241', color: '#FCFCFC', boxShadow: '0px 3px 0px #BE8F18' },
        _focus: { background: '#585241', color: '#FCFCFC', boxShadow: '0px 3px 0px #BE8F18' },
        _invalid: { bg: '#2B040D', border: '1px solid #D92048;', boxShadow: '0px 3px 0px #6F0A20' },
        _disabled: { 
          opacity: 0.7,
          color: '#FCFCFC',
          cursor: "not-allowed",
          boxShadow: 'none',
          border: '1px solid #8E8F96',
          bg: '#202330',
          _hover: {
            border: '1px solid #966F02'
          }
        },
      }
    }
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'default'
  }
}

export default Input