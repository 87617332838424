import { numberInputAnatomy as parts } from "@chakra-ui/anatomy"
import { calc, cssVar, mode } from "@chakra-ui/theme-tools"
import Input from "./input"

const { variants, defaultProps } = Input

const $stepperWidth = cssVar("number-input-stepper-width")

const $inputPadding = cssVar("number-input-input-padding")
const inputPaddingValue = calc($stepperWidth).add("0.5rem").toString()

const baseStyleRoot = {
  [$stepperWidth.variable]: "24px",
  [$inputPadding.variable]: inputPaddingValue,
}

const baseStyleField = Input.baseStyle?.field ?? {}

const baseStyleStepperGroup = {
  width: '52px'
}

const baseStyleStepper = (props) => {
  return {
    borderStart: "1px solid",
    borderStartColor: mode("inherit", "whiteAlpha.300")(props),
    color: mode("inherit", "whiteAlpha.800")(props),
    _active: {
      color: '#F8D060',
      bg: mode("gray.200", "whiteAlpha.300")(props),
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
    },
  }
}

const baseStyle = (props) => ({
  root: baseStyleRoot,
  field: baseStyleField,
  stepperGroup: baseStyleStepperGroup,
  stepper: baseStyleStepper(props),
})


function getSize(size) {
  const sizeStyle = Input.sizes[size]

  const radius = {
    lg: "md",
    md: "md",
    sm: "sm",
    xs: "sm",
  }

  return {
    field: {
      ...sizeStyle.field,
      paddingInlineEnd: $inputPadding.reference,
      verticalAlign: "top",
    },
    stepper: {
      _first: {
        borderTopEndRadius: radius[size],
      },
      _last: {
        borderBottomEndRadius: radius[size],
        mt: "-1px",
        borderTopWidth: 1,
      },
    },
  }
}

const sizes = {
  md: getSize("md"),
}

export default {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants,
  defaultProps,
}