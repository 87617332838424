import { alertAnatomy as parts } from "@chakra-ui/anatomy"

const baseStyle = {
  container: {
    px: 4,
    py: 3,
    // display: 'block',
    borderRadius: '16px',
    color: '#202330',
  },
  title: {
    fontWeight: 600,
    lineHeight: 6,
    marginEnd: 2,
  },
  description: {
    fontWeight: 400,
    lineHeight: 0,
  },
  icon: {
    flexShrink: 0,
    marginEnd: 3,
    w: 5,
    h: 6,
  },
  spinner: {
    flexShrink: 0,
    marginEnd: 3,
    w: 5,
    h: 5,
  },
}


const variants = {
  default: {
    container: {
      background: '#47D7E0',
      boxShadow: '0px 3px 0px #1E6B71',
    }
  },
  warning: {
    container: {
      background: '#F8D060',
      boxShadow: '0px 3px 0px #BE8F18'
    }
  },
  danger: {
    container: {
      background: '#D92048',
      boxShadow: '0px 3px 0px #6F0A20',
      color: '#FCFCFC'
    },
    title: {
      fontWeight: 500,
    },
  },
}

const defaultProps = {
  variant: "default",
}

export default {
  parts: parts.keys,
  baseStyle,
  variants,
  defaultProps,
}