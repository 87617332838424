const Checkbox = {
  // The styles all button have in common
  baseStyle: {
    control: {
      w: "100%",
      transitionProperty: "box-shadow",
      transitionDuration: "normal",
      border: "1px solid #8E8F96",
      borderRadius: "4px",
      color: '#272A37',
      bg: '#272A37',
      boxShadow: '0px 3px 0px #434658',

      _checked: {
        bg: '#272A37',
        border: '1px solid #F2B724',
        boxShadow: '0px 3px 0px #434658',
        color: '#F8D060',

        _disabled: {
          color: '#BE8F18',
          border: '1px solid #8E8F96',
          bg: '#434658',
          boxShadow: '0px 3px 0px #434658',
        },

        _invalid: {
          color: '#FC8181'
        }
      },

      _indeterminate: {
        boxShadow: '0px 3px 0px #434658',
        border: "1px solid #8E8F96",
        color: '#F8D060',
        bg: '#272A37',
      },

      _disabled: {
        bg: '#434658',
        border: '1px solid #8E8F96;',
        boxShadow: '0px 3px 0px #434658',
      },

      _invalid: {
        bg: '#2B040D', 
        border: '1px solid #D92048', 
        boxShadow: '0px 3px 0px #6F0A20'
      }
    },
    container: {
      _hover: {
        "> span.chakra-checkbox__control[data-disabled]": {
          bg: '#434658',
          border: '1px solid #8E8F96;',
          boxShadow: '0px 3px 0px #434658',
          _hover: {
            boxShadow: '0px 3px 0px #434658',
            border: '1px solid #8E8F96',
          }
        },
        "> span.chakra-checkbox__control": {
          bg: '#434658',
          border: '1px solid #F8D060',
          boxShadow: '0px 3px 0px #BE8F18'
        }
      }
    }
  },
  sizes: {
    md: {
      control: { w: '24px', h: '24px' },
    }
  },
  // The default size
  defaultProps: {
    size: 'md',
  }
}

export default Checkbox