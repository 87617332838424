import Input from './input'

const Textarea = {
  baseStyle: {
    ...Input.baseStyle.field,
    paddingY: "2",
    minHeight: "40",
    lineHeight: "short",
    verticalAlign: "top",
  },
  sizes: {
    md: Input.sizes.md.field
  },
  variants: {
    default: Input.variants.default.field
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'default'
  }
}

export default Textarea