import { extendTheme } from '@chakra-ui/react'

// Global style overrides
// import styles from './foundations/styles'

// Foundational style overrides
import fonts from './foundations/fonts'
import borders from './foundations/borders'

// Component style overrides
import Button from './components/button'
import IconButton from './components/iconButton'
import Input from './components/input'
import Textarea from './components/textarea'
import Checkbox from './components/checkbox'
import Radio from './components/radio'
import Tag from './components/tag'
import Alert from './components/alert'
import NumberInput from './components/numberInput'
import Table from './components/table'
import Modal from './components/modal'
import Menu from './components/menu'
import Popover from './components/popover'

const breakpoints = {
  xs: '12em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
  '3xl': '114em',
  '4xl': '132em'
}

const overrides = {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  breakpoints,
  fonts,
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: '#1B1E2A',
      },
    },
  },
  borders,
  // Other foundational style overrides go here
  components: {
    Alert,
    Button,
    IconButton,
    Input,
    Textarea,
    Checkbox,
    Radio,
    Tag,
    NumberInput,
    Table,
    Modal,
    Menu,
    Popover,
    // Other components go here
  }
}

export default extendTheme(overrides)