const IconButton = {
  // The styles all button have in common
  baseStyle: {
    borderRadius: '8px',
    fontWeight: '400'
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      padding: '8px, 24px',
      borderRadius: '12px'
    },
    md: {
      fontSize: 'md',
      padding: '16px 24px',
      borderRadius: '16px'
    }
  },
  variants: {
    default: {
      bg: '#F8D060',
      color: '#202330',
      boxShadow: '0px 3px 0px #BE8F18;',
      _hover: { bg: '#F2B724' }
    },
    secondary: {
      bg: '#272A37;',
      color: '#FCFCFC',
      border: '1px solid #FCFCFC',
      boxShadow: '0px 3px 0px #434658',
      _hover: { bg: '#434658', border: '1px solid #F8D060', boxShadow: '0px 3px 0px #BE8F18', color:' #F8D060' }
    }
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'default'
  }
}

export default IconButton