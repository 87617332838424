export const LOGGED_IN = 'LOGGED_IN';
export const UPDATE_USER_ACCESS_TOKEN = 'UPDATE_USER_ACCESS_TOKEN';
export const UPDATE_USER_IS_FETCH = 'UPDATE_USER_IS_FETCH';
export const UPDATE_USER_ORG_ROLE = 'UPDATE_USER_ORG_ROLE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_ORG_NAME = 'UPDATE_ORG_NAME';
export const UPDATE_ORG = 'UPDATE_ORG';
export const LOADING_ACTIVE = 'LOADING_ACTIVE';
export const LOADING_INACTIVE = 'LOADING_INACTIVE';
export const UPDATE_CURRENT_ERROR = 'UPDATE_CURRENT_ERROR';
export const UPDATE_PAGE_TITLE = 'UPDATE_PAGE_TITLE';
export const EXTEND_PAGE_TITLE = 'EXTEND_PAGE_TITLE';
export const UPDATE_UE_GAME = 'UPDATE_UE_GAME';
export const UPDATE_UE_GAME_FILES = 'UPDATE_UE_GAME_FILES';
export const UPDATE_UE_GAME_UPLOADED_FILES = 'UPDATE_UE_GAME_UPLOADED_FILES';
export const UPDATE_UE_GAME_UPLOADED_FILES_BLOB = 'UPDATE_UE_GAME_UPLOADED_FILES_BLOB';
export const UPDATE_UE_GAME_STATE = 'UPDATE_UE_GAME_STATE';
export const ADD_GENERATED_VOICE_FILE = 'ADD_GENERATED_VOICE_FILE'
export const ADD_GENERATED_VOICE_FILE_BLOB = 'ADD_GENERATED_VOICE_FILE_BLOB'